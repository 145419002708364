import React from 'react';
import { Link  } from 'react-router-dom';

const MainPageSali = () => {

  const pages = [
    { id: 1, name: 'Sala 5', path: '/Sala5' },
    { id: 2, name: 'Sala 9', path: '/Sala9' },
    { id: 3, name: 'Sala 16', path: '/Sala16' },
    { id: 4, name: 'Sala 33', path: '/Sala33' },
    { id: 5, name: 'Sala 34', path: '/Sala34' },
    { id: 6, name: 'Sala 76', path: '/Sala76' },
    { id: 7, name: 'Sala 102', path: '/Sala102' },
    { id: 8, name: 'Sala 130', path: '/Sala130' },
    { id: 9, name: 'Sala 162', path: '/Sala162' },
    { id: 10, name: 'Sala 165', path: '/Sala165' },
    { id: 11, name: 'Sala 166', path: '/Sala166' },
    { id: 12, name: 'Sala 167', path: '/Sala167' },
    { id: 13, name: 'Sala 168', path: '/Sala168' },
    { id: 14, name: 'Sala 169', path: '/Sala169' },
    { id: 15, name: 'Sala 249', path: '/Sala249' },
  ];

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>Alegeți sala de judecata</h2>
      <div style={{
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: '16px', 
        justifyContent: 'center',
        padding: '20px'
      }}>
        {pages.map((page) => (
          <Link
          key={page.id}
          to={page.path}
          style={{
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '20px',
            width: '200px',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: '#f5e9db',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {page.name}
        </Link>
        ))}
      </div>
    </div>
  );
};

export default MainPageSali;
