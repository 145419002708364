import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
    Nav,
    NavItem,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    NavLink,
    Collapse,
    NavbarToggler,
    Navbar
} from "reactstrap";
import "../../css/headerNavigation.css"; 

class NavBarComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDropdown: null, // Stare unică pentru dropdown activ
            collapsed: true,
        };
    }

    toggleNavbar = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    closeNavbar = () => {
        if (!this.state.collapsed) {
            this.toggleNavbar();
        }
    }

    handleMouseEnter = (dropdown) => {
        this.setState({ activeDropdown: dropdown }); // Activează doar dropdown-ul curent
    }

    handleMouseLeave = () => {
        this.setState({ activeDropdown: null }); // Dezactivează dropdown-urile
    }

 
    render() {
        return (
            <section id="55" className="bg-light py-3">
                <h1 style={{ fontFamily: "Monotype Corsiva", padding: "10px", fontSize: "44px", textAlign: "center" }}>
                    <img style={{ paddingTop: '10px' }} src={require("../../images/mjLogo.png")} alt="mj" /> Tribunalul Cluj
                </h1>
                <Navbar className="text-nowrap navbar-light bg-light" expand="lg">
                    <NavbarToggler onClick={this.toggleNavbar} />
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav className="m-auto" navbar>
                            <NavItem className="mr-2">
                                <NavLink className="font-weight-bold nav-link" onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/`}>Acasă</NavLink>
                            </NavItem>
                            
                            {/* Dropdown Despre Instituție */}
                            <Dropdown nav
                                isOpen={this.state.activeDropdown === 'instanta'}
                                onMouseEnter={() => this.handleMouseEnter('instanta')}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <DropdownToggle nav caret className="font-weight-bold nav-link">Despre Instituţie</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/prezentare`}>Prezentare</DropdownItem>
                                    <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/competente`}>Competenţe şi Circumscripţie</DropdownItem>
                                    <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/rapoarte`}>Instanţa în cifre</DropdownItem>
                                    <DropdownItem header>Organizare</DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/conducere`}>Conducere</DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/organizare`}>Structură organizatorică</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            
                            {/* Dropdown Informații publice */}
                            <Dropdown nav
                                isOpen={this.state.activeDropdown === 'informatii'}
                                onMouseEnter={() => this.handleMouseEnter('informatii')}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <DropdownToggle nav caret className="font-weight-bold nav-link">Informaţii publice</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="https://www.curteadeapelcluj.ro/index.php/anunturi/concursuri.html?month=jan&yr=2020">Concursuri</DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/infoPublice`}>Informaţii de interes public</DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/declaratii`}>Declaraţii</DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/comunicate`}>Comunicate de presă</DropdownItem>
                                    <DropdownItem href={`${process.env.PUBLIC_URL}/hotarari`}>Hotărâri de colegiu</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                            {/* Dropdown Instanțe */}
                            <Dropdown nav
                                isOpen={this.state.activeDropdown === 'instante'}
                                onMouseEnter={() => this.handleMouseEnter('instante')}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <DropdownToggle nav caret className="font-weight-bold nav-link">Instanţe</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="http://portal.just.ro/211/SitePages/acasa_default.aspx?id_inst=211">Judecătoria Cluj-Napoca</DropdownItem>
                                    <DropdownItem href="http://portal.just.ro/219/SitePages/acasa_default.aspx?id_inst=219">Judecătoria Dej</DropdownItem>
                                    <DropdownItem href="http://portal.just.ro/242/SitePages/acasa_default.aspx?id_inst=242">Judecătoria Huedin</DropdownItem>
                                    <DropdownItem href="http://portal.just.ro/235/SitePages/acasa_default.aspx?id_inst=235">Judecătoria Gherla</DropdownItem>
                                    <DropdownItem href="http://portal.just.ro/328/SitePages/acasa_default.aspx?id_inst=328">Judecătoria Turda</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                            {/* Dropdown Liste de ședințe */}
                            <Dropdown nav
                                isOpen={this.state.activeDropdown === 'listeSedinte'}
                                onMouseEnter={() => this.handleMouseEnter('listeSedinte')}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <DropdownToggle nav caret className="font-weight-bold nav-link">Liste de şedinţe</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.closeNavbar} href="http://portal.just.ro/117/SitePages/Lista_Sedinte.aspx?id_inst=117">Liste sedinte</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                            {/* Dropdown Jurisprudență */}
                            <Dropdown nav
                                isOpen={this.state.activeDropdown === 'jurisprudenta'}
                                onMouseEnter={() => this.handleMouseEnter('jurisprudenta')}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <DropdownToggle nav caret className="font-weight-bold nav-link">Jurisprudenţă</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaCivila`}>Secția Civilă</DropdownItem>
                                    <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaPenala`}>Secția Penală</DropdownItem>
                                    <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaMixta`}>Secția mixtă de contencios și administrativ</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                            <NavItem className="mr-2">
                                <NavLink className="font-weight-bold nav-link" onClick={this.closeNavbar} href="https://www.curteadeapelcluj.ro/index.php/dosare/info-dosar.html">Info-Dosar</NavLink>
                            </NavItem>
                            <NavItem className="mr-2">
                                <NavLink className="font-weight-bold nav-link" onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/contact`}>Contact</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </section>
        );
    }
}

export default NavBarComp;