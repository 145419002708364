import React, { Component } from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import Contact from "./pages/contact";
import Prezentare from "./pages/prezentare";
import HomePage from "./pages/home";
import TaxeTimbru from "./pages/CalculatorTaxe";
import Organizare from "./pages/Organizare/organizare";
import Conducere from "./pages/Organizare/conducere";
import Footer from "./components/Footer/FooterComponent";
import Rapoarte from "./pages/rapoarte";
import Hotarari from "./pages/hotarari";
import Jurisprudenta from "./pages/jurisprudenta";
import Concursuri from "./pages/concursuri";
import Competente from "./pages/competente";
import Declaratii from "./pages/declaratii";
import InfoPublice from "./pages/infoPublice";
import MasuriCovid19 from "./pages/masuriCovid19";
import SectiaCivila from "./pages/ListeUrgente/sectiaCivila";
import SectiaPenala from "./pages/ListeUrgente/sectiaPenala";
import SectiaMixta from "./pages/ListeUrgente/sectiaMixta";
import JurisprudentaSectiaCivila from "./pages/Jurisprudenta/jurisprudentaSectiaCivila";
import JurisprudentaSectiaPenala from "./pages/Jurisprudenta/jurisprudentaSectiaPenala";
import JurisprudentaSectiaMixta from "./pages/Jurisprudenta/jurisprudentaSectiaMixta";
import TaxeJudiciare from "./pages/taxeJudiciare";

//Pagini pentru salile de judecata live
import MainPageSali from "./pages/SaliJudecata/MainPageSali";
import Sala5 from "./pages/SaliJudecata/Sala5"
import Sala9 from "./pages/SaliJudecata/Sala9"
import Sala16 from "./pages/SaliJudecata/Sala16"
import Sala33 from "./pages/SaliJudecata/Sala33"
import Sala34 from "./pages/SaliJudecata/Sala34"
import Sala76 from "./pages/SaliJudecata/Sala76"
import Sala102 from "./pages/SaliJudecata/Sala102"
import Sala130 from "./pages/SaliJudecata/Sala130"
import Sala162 from "./pages/SaliJudecata/Sala162"
import Sala165 from "./pages/SaliJudecata/Sala165"
import Sala166 from "./pages/SaliJudecata/Sala166"
import Sala167 from "./pages/SaliJudecata/Sala167"
import Sala168 from "./pages/SaliJudecata/Sala168"
import Sala169 from "./pages/SaliJudecata/Sala169"
import Sala249 from "./pages/SaliJudecata/Sala249"

import CustomizedMenu from './components/customizedMenu'

import Comunicate from "./pages/comunicate";
import ProtectiaDatelor from "./pages/protectiaDatelor";
import ListeExperti from "./pages/listeExperti";
import Legalizare from "./pages/legalizare";
import ScrollToTop from "react-scroll-up";
import NavBarComp from "./components/Header/HeaderNavigation";
import { Icon } from "react-icons-kit";
import { circleTop } from "react-icons-kit/iconic/circleTop";
import Tooltip from "@material-ui/core/Tooltip";
import CookieConsent from "react-cookie-consent";

const { detect } = require("detect-browser");
const browser = detect();

class App extends Component {
  render() {
    if (
      (browser.name === "ie" && browser.version.substr(0, 2) === "9") ||
      (browser.name === "ie" && browser.version.substr(0, 2) === "8") ||
      (browser.name === "ie" && browser.version.substr(0, 2) === "7") ||
      (browser.name === "ie" && browser.version.substr(0, 2) === "6")
    ) {
      return this.renderForNoCompatibility();
    } else return this.renderAll();
  }
  renderForNoCompatibility() {
    return (
      <div>
        <h2>
          Acest site nu suporta aceasta versiune de browser. Va rugam sa
          updataţi browserul dumneavostră sau să folositi Chrome sau FireFox{" "}
        </h2>
      </div>
    );
  }

  renderAll() {
    return (
      <React.Fragment>
        <NavBarComp />

        <Switch>
          <Route exact path={"/organizare"} component={Organizare} />
          <Route exact path={"/conducere"} component={Conducere} />
          <Route exact path={"/contact"} component={Contact} />
          <Route exact path={"/taxe"} component={TaxeTimbru} />
          <Route exact path={"/prezentare"} component={Prezentare} />
          <Route exact path={"/rapoarte"} component={Rapoarte} />
          <Route exact path={"/hotarari"} component={Hotarari} />
          <Route exact path={"/jurisprudenta"} component={Jurisprudenta} />
          <Route exact path={"/concurs"} component={Concursuri} />
          <Route exact path={"/declaratii"} component={Declaratii} />
          <Route exact path={"/infoPublice"} component={InfoPublice} />
          <Route exact path={"/comunicate"} component={Comunicate} />
          <Route exact path={"/competente"} component={Competente} />
          <Route exact path={"/legalizare"} component={Legalizare} />
          <Route exact path={"/gdpr"} component={ProtectiaDatelor} />
          <Route exact path={"/experti"} component={ListeExperti} />
          <Route exact path={"/masuriCovid19"} component={MasuriCovid19} />
          <Route exact path={"/sectiaCivila"} component={SectiaCivila} />
          <Route exact path={"/sectiaPenala"} component={SectiaPenala} />
          <Route exact path={"/sectiaMixta"} component={SectiaMixta} />
          <Route exact path={"/jurisprudentaSectiaCivila"} component={JurisprudentaSectiaCivila} />
          <Route exact path={"/jurisprudentaSectiaPenala"} component={JurisprudentaSectiaPenala} />
          <Route exact path={"/jurisprudentaSectiaMixta"} component={JurisprudentaSectiaMixta} />
          <Route exact path={"/taxeJudiciare"} component={TaxeJudiciare} />

          <Route exact path={"/salaLive"} component={MainPageSali} />
          <Route exact path={"/sala5"} component={Sala5} />
          <Route exact path={"/sala9"} component={Sala9} />
          <Route exact path={"/sala16"} component={Sala16} />
          <Route exact path={"/sala33"} component={Sala33} />
          <Route exact path={"/sala34"} component={Sala34} />
          <Route exact path={"/sala76"} component={Sala34} />
          <Route exact path={"/sala34"} component={Sala76} />
          <Route exact path={"/sala102"} component={Sala102} />
          <Route exact path={"/sala130"} component={Sala130} />
          <Route exact path={"/sala162"} component={Sala162} />
          <Route exact path={"/sala165"} component={Sala165} />
          <Route exact path={"/sala166"} component={Sala166} />
          <Route exact path={"/sala167"} component={Sala167} />
          <Route exact path={"/sala168"} component={Sala168} />
          <Route exact path={"/sala169"} component={Sala169} />
          <Route exact path={"/sala249"} component={Sala249} />

          <Route exact path={"/"} component={HomePage} />

        </Switch>

        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Am inteles."
          cookieName="gdprCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          hideOnAccept={true}
        >
          Acest site folosește cookie-uri. Navigând în continuare vă exprimați
          acordul pentru folosirea cookie-urilor conform Regulamentului (UE)
          2016/679{" "}
          <span>
            <a href="/gdpr"> Vezi detalii.</a>
          </span>
        </CookieConsent>
        <CustomizedMenu style={{position: 'fixed'}}>

        </CustomizedMenu>
        <ScrollToTop showUnder={160}>
          <Tooltip title="Back to top">
            <Icon style={{ color: "#8c7b75" }} size={40} icon={circleTop} />
          </Tooltip>
        </ScrollToTop>
        
      </React.Fragment>
    );
  }
}

export default App;
