import React from 'react';

const WebPage = ({ url }) => {
  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <iframe
        src={url}
        title="Embedded Web Page"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default WebPage;